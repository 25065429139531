.pdf-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .pdf-table th, .pdf-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  .pdf-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .pdf-table a {
    display: inline-block;
    padding: 8px 16px;
    text-decoration: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .pdf-table a:hover {
    background-color: #0056b3;
  }
  