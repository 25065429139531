body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.hide-border:before,
.hide-border:after {
  display: none;
}
.dd-item::marker {
  display: none !important;
  font-size: 0px;
}
.btn-outline-secondary:hover {
  color: #ffffff !important;
}

.rsm-svg {
  height: 100%;
  width: 100%;
}

.modal {
  z-index: 11112 !important;
}

.image-gallery-play-button {
  display: none;
}

.image-gallery {
  width: 50%;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 70px !important;
  width: 60px !important;
}

.card .header .header-dropdown li .dropdown-menu,
.header-dropdown .dropdown-menu {
  background: #17191c;
  border-radius: 0.55rem;
  overflow: hidden;
  transform: none !important;
  left: auto !important;
  right: 10px !important;
  padding: 10px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
  border: 0;
  top: 10px !important;
  color: #999 !important;
}
.card .header .header-dropdown li a {
  color: #999 !important;
  padding: 10px;
  white-space: nowrap;
  font-size: 14px;
}

.card .header .header-dropdown li a:hover {
  color: #fff !important;
}
.accordion .card .card-header {
  background-color: transparent !important;
}
.accordion .card .card-header .btn-link {
  color: #666;
}

.accordion .card-body {
  font-size: 15px;
  line-height: 28px;
  color: #777;
}

.button-container {
  display: none !important;
}

.hide-toggle:after {
  display: none !important;
  margin-left: 0em !important;
  vertical-align: 0em !important;
  content: "" !important;
  border-top: 0em solid !important;
  border-right: 0em solid transparent !important;
  border-bottom: 0 !important;
  border-left: 0em solid transparent !important;
}

.form-control {
  height: calc(1.5em + 0.75rem + 5px) !important;
}

.custom-text-area-height {
  height: 200px !important;
}

.hide-toggle .btn-outline-secondary {
  height: calc(1.5em + 0.75rem + 5px) !important;
}
.jvectormap-container {
  height: 300px;
}
.jvectormap-zoomin {
  position: absolute;
  top: 0px;
  background: #000;
  color: #fff;
  padding: 2px 7px;
}
.jvectormap-zoomout {
  position: absolute;
  top: 27px;
  background: #000;
  color: #fff;
  padding: 2px 7px;
}
.list-group-item + .list-group-item {
  border-top-width: 1px !important;
}
.hclass {
  left: -40px !important;
}

/*  */
.text-margin-none > p {
  margin: 0 !important;
}
/*  */

/* react quill text editor */
.react-quill .ql-container {
  min-height: 100px !important;
}

.ck-content {
  min-height: 100px !important;
}

/*  */
.input-file-button {
  width: 100%;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.input-file {
  display: none;
}

table thead th:last-child,
table tbody tr td:last-child {
  position: sticky;
  right: 0;
  z-index: 2;
  background-color: #f4f7f6;
  height: 100%;
}

@media print {
  @page {
    size: A4;
    margin: 1cm;
  }

  .page-break {
    page-break-before: always;
  }
}

.rtl-list {
  direction: rtl;
}
