.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: "Jameel Noori Nastaleeq";
  src: url("./assets/fonts/Jameel\ Noori\ Nastaleeq.ttf") format("truetype");
}

@font-face {
  font-family: "Al Qalam Quran Majeed Web";
  src: url("./assets/fonts/Al_Qalam_Quran_Majeed_Web.ttf") format("truetype");
}

@font-face {
  font-family: "MB Lateefi";
  src: url("./assets/fonts/MBLateefi.ttf")
    format("truetype");
}

.noto-nastaliq-urdu {
  font-family: "Jameel Noori Nastaleeq";
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.noto-nastaliq-arbi {
  font-family: "Al Qalam Quran Majeed Web";
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.noto-nastaliq-sindhi {
  font-family: "MB Lateefi";
  font-optical-sizing: auto;
  /* font-weight: 700; */
  font-style: normal;
}
.mcqs_cls {
  display: flex;
  align-items: flex-start;
  direction: rtl;
  margin: 0;
}
.direction_rtl{
  direction: rtl;
}
.mcqs_cls2 {
  margin-left: 1rem; font-weight: 500;
}

.text-decoration-cls {
  text-decoration: underline;
}
.border-cls {
  border: 1px solid black;
}
.borderRight {
  border-right: 1px solid black;
}
.borderLeft {
  border-left: 1px solid black;
}
.borderBottom {
  border-bottom: 1px solid black;
}
.borderTop {
  border-top: 1px solid black;
}
.w5Per {
  width: 5%;
}
.w95Per {
  width: 95%;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.auth-main::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 400px;
  height: 100%;
  z-index: -1;
  /* background: #191f28; */
}
.card::before {
  width: 150px;
  padding: 5px;
}
.body:after {
  height: 5px;
  width: 100%;
  position: fixed;
  content: "";
  z-index: 999;
}
.auth-main:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  /* background: url(../../assets/images/auth_bg.jpg) no-repeat top left fixed; */
}
#wrapper:after {
  right: 0;
}
#wrapper:before,
#wrapper:after {
  height: 100vh;
  /* width: 5px; */
  position: fixed;
  content: "";
  z-index: 999;
  top: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}
#wrapper:before {
  left: 0;
}
.headerName {
  font-size: 36px;
  align-self: center;
  text-align: center;
}
.headerContainer {
  flex-direction: row;
}

.jvectormap-container {
  height: 300px;
}

.wrs_tickContainer{
  display: none !important;
}